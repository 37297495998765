@mixin x-icon-button() {
    button.icon-btn {
      margin-right: 15px;
      font-weight: normal;
      font-size: 13px;
      padding: 0 15px;
      > * {
        display: inline-block;
        vertical-align: middle;
      }

      .mat-icon {
        margin-right: 8px;
        height: 18px;
        width: 15px;
        font-size: 18px;
      }

      .btn-text {
        @extend .ellipsis;
      }

      .btn-spinner {
        margin-right: 5px;
        circle {
          stroke: white;
        }
      }
    }

    @media screen and (max-width: 768px) {
      button.icon-btn {
        line-height: 21px;
        height: 30px;
        width: 30px;
        min-width: 0;
        padding: 0 10px;
        .mat-icon {
          font-size: 17px;
          height: 17px;
          width: 17px;
          margin: 0;
        }
        span.btn-text {
          display: none;
        }
      }
    }

    @media screen and (max-width: 1600px) {
      button.icon-btn {
        margin-right: 12px;
        padding: 0 14px;
        span.btn-text {
          font-size: 12px !important;
          max-width: 150px;
          line-height: 36px;
        }
      }
    }

    @media screen and (max-width: 900px) {
      button.icon-btn {
        margin-right: 8px;
        padding: 0 10px 0 6px;
      }
    }
  }
