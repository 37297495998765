@import '~@fortawesome/fontawesome-free-webfonts/css/fontawesome.css';
@import 'app/shared/styles/mixins';
@import 'app/shared/styles/form';
@import 'app/shared/styles/button';
@import 'app/shared/styles/sticky-header';
@import 'app/shared/styles/sidenav';
@import 'app/shared/styles/fire-order';

$primary-color: #461e7d;
$secondary-color: #5738ff;
$gray: #888888;

global-footer {
  display: block;
  padding: 40px 25px 25px;
}

.main-container {
  input:focus,
  input:active,
  input:hover,
  select:focus,
  select:active,
  select:hover,
  textarea:focus,
  textarea:active,
  textarea:hover {
    border: none;
  }
}

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}

.alert-warning hr {
  border-top-color: #ffe8a1;
}

.alert-warning .alert-link {
  color: #533f03;
}

global-nav {
  position: fixed;
  width: 100%;
  z-index: 999;
}

lib-ngx-deeplinker {
  button {
    background-color: #fff;
    color: #461e7d;
    border: 1px solid #461e7d;
    padding: 6px 10px !important;
  }
}
.mobile-only {
  display: none;
}
.desktop-only {
  display: block;
}


@media screen and (max-width: 768px) {
  .mobile-only {
    display: block;
  }
  .desktop-only {
    display: none;
  }
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
