form.asset-form {
  margin-top: 65px;
}

.form-group {
  max-width: 800px;
  margin: 10px 0 10px 65px;
}

.form-divider {
  max-width: 1024px;
  margin-left: 0;
}
form-header {
  color: var(--primary-color);
  margin: 30px 0 15px;
  max-width: 1024px;
  border-bottom: solid 1px #dddddd;
  padding: 10px 5px;
  &.with-button {
    display: flex;
    justify-content: space-between;
    max-width: 1024px;
    margin-right: 40px;
    h4 {
      flex-grow: 3;
      padding: 0;
      align-self: center;
    }
    .icon-btn {
      align-self: flex-end;
      flex-grow: 0;
      margin-right: 0 !important;
    }
    @include x-icon-button;
  }
}
.expandable-header {
  color: #461e7d;
  cursor: pointer;
  span {
    vertical-align: middle;
  }
  .mat-icon {
    vertical-align: middle;
    line-height: 25px;
  }
}

@media screen and (max-width: 768px) {
}
