.fire-order-section {
  max-width: 900px;
  .fire-order-subheader {
    color: #461e7d;
    font-weight: 500;
  }
  mat-expansion-panel {
    margin-bottom: 10px;
    mat-expansion-panel-header {
      line-height: 26px;
    }
    .mat-expansion-panel-body {
      padding: 0 10px 16px;
    }
    .step-controls {
      width: 750px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .add-button {
      display: none;
    }
    .mat-icon {
      font-size: 20px;
      height: 20px;
      width: 20px;
    }
    .drag-handle {
      position: absolute;
      top: 4px;
      left: 4px;
      background: #eee;
      border-radius: 4px;
      &:hover {
        cursor: grab;
      }
      &:active, &:focus {
        cursor: grabbing;
      }
    }
    .play-button {
      margin-left: 25px;
      margin-top: 4px;
      transform: rotate(0);
      -webkit-transform: rotate(0);
      transition: .25s ease-in-out;
      -webkit-transition: .25s ease-in-out;
    }
    .step-panel .mat-content {
      margin-left: 40px;
      top: -15px;
      font-size: 13px;
      display: block;
      overflow: visible;
    }
  }
}

.fire-order-section.view mat-expansion-panel .step-panel {
  .mat-content {
    margin-left: 10px;
    .play-button {
      margin-left: 0;
    }
  }
  .check-label {
    margin-left: 0;
  }
}

mat-expansion-panel-header {
  &.expanded {
    .play-button {
      transform: rotate(90deg);
      -webkit-transform: rotate(90deg);
    }
    .panel-description {
      display: none;
    }
  }
}

.panel-description-view, .panel-description.mat-expansion-panel-header-description {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  letter-spacing: -1px;
  text-align: center;
  padding-left: 10px;
}

.step-field-wrapper {
  display:inline-flex;
  width: 33.333%;
  font-size: 12px;
  line-height: 26px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.54);
  .count {
    padding-left: 5px;
    color: #000;
  }
  &.full-width {
    width: 100%;
  }
}

.panel-description.mat-expansion-panel-header-description {
  margin-left: -50px;
}

.panel-description-view {
  margin-bottom: 25px;
}

app-fire-order-step:last-of-type mat-expansion-panel {
  .add-button {
    display: inline-block;
  }
}

.step-section {
  .before-actions-wrapper {
    position:absolute;
    left: 10px;
    top: 10px;
  }
  .after-actions-wrapper {
    position: absolute;
    right: 10px;
    mat-icon {
      color: #461e7d;
    }
  }
}

.fire-order-group {
  .mat-form-field {
    margin-left: 20px;
    width: 190px;
    &.double-width {
      width: 400px;
    }
  }
  &.check-group {
    padding: 30px 0 10px;
    border: solid 1px #eee;
    margin: 0 0 10px;
    border-radius: 4px;
    position: relative;
    .delete-check {
      position: absolute;
      top: 10px;
      right: 10px;
      color: #461e7d;
      cursor: pointer;
    }
  }
}

.view .fire-order-group.check-group {
  padding: 0px 0 10px;
}

.check-label {
  margin: 0 20px 10px;
  display: block;
  font-weight: 500;
  color: #461e7d;
}

.check-field-wrapper {
  padding: 10px 10px 0;
  display: inline-flex;
  width: 33.333%;
  font-size: 12px;
  mat-label {
    font-weight: bold;
  }
  .check-value {
    padding-left: 20px;
  }
  &.full-width {
    width: 100%;
  }
}

.add-check-button {
  float: right;
}

@media screen and (max-width: 768px) {
  .panel-description-view, .panel-description.mat-expansion-panel-header-description {
    display: none;
  }
  .mat-expansion-panel-header {
    height: 52px !important;
  }
}
