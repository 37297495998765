@import '~@angular/material/theming';

$light-text: #ffffff;
$light-primary-text: $light-text;
$light-accent-text: rgba($light-primary-text, 0.7);
$light-disabled-text: rgba($light-primary-text, 0.5);
$light-dividers: rgba($light-primary-text, 0.12);
$light-focused: rgba($light-primary-text, 0.12);

$font: var(--font);

$fontConfig: (
  display-4: mat-typography-level(112px, 112px, 300, $font, -0.0134em),
  display-3: mat-typography-level(56px, 56px, 400, $font, -0.0089em),
  display-2: mat-typography-level(45px, 48px, 400, $font, 0em),
  display-1: mat-typography-level(34px, 40px, 400, $font, 0.0074em),
  headline: mat-typography-level(24px, 32px, 400, $font, 0em),
  title: mat-typography-level(20px, 32px, 500, $font, 0.0075em),
  subheading-2: mat-typography-level(16px, 28px, 400, $font, 0.0094em),
  subheading-1: mat-typography-level(15px, 24px, 500, $font, 0.0067em),
  body-2: mat-typography-level(14px, 24px, 500, $font, 0.0179em),
  body-1: mat-typography-level(14px, 20px, 400, $font, 0.0179em),
  button: mat-typography-level(14px, 14px, 500, $font, 0.0893em),
  caption: mat-typography-level(12px, 20px, 400, $font, 0.0333em),
  input: mat-typography-level(inherit, 1.125, 400, $font, 1.5px),
);

@include mat-core($fontConfig);

.main-container {
  --text-primary-color: #{$light-primary-text};
  --text-primary-lighter-color: #{$dark-primary-text};
  --text-primary-darker-color: #{$light-primary-text};

  --text-accent-color: #{$light-primary-text};
  --text-accent-lighter-color: #{$dark-primary-text};
  --text-accent-darker-color: #{$light-primary-text};

  --text-warn-color: #{$light-primary-text};
  --text-warn-lighter-color: #{$dark-primary-text};
  --text-warn-darker-color: #{$light-primary-text};
}

$theme-colors: (
        main: var(--primary-color),
        lighter: var(--primary-lighter-color),
        darker: var(--primary-darker-color),
        200: var(--primary-color),
        contrast: (
                main: $light-primary-text,
                lighter: $dark-primary-text,
                darker: $light-primary-text,
        ),
);
$primary-palette: mat-palette($theme-colors, main, lighter, darker);

$mat-warn: (
        main: #c32b2q,
        lighter: #ffe6ae,
        darker: #c32b2q,
        200: #c32b2q,
        contrast: (
                main: $light-primary-text,
                lighter: $dark-primary-text,
                darker: $light-primary-text,
        ),
);

$theme-warn: mat-palette($mat-warn, main, lighter, darker);
$primary-theme: mat-light-theme($primary-palette, $primary-palette, $mat-warn);
@include angular-material-theme($primary-theme);
